import React from 'react';
import { Link } from 'gatsby';
import Header from './../shared/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { PageNotFound } from './../../elements/PageNotFoundElements';

const PageNotFoundContent = () => (
    <PageNotFound>
        <Header page="Page Not Found" />
        <div className="container">
            <div className="page-not-found-details">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <h2>Page not found</h2>
                <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                <Link className="btn btn-primary" to="/">Go Home</Link>
            </div>
        </div>
    </PageNotFound>
)

export default PageNotFoundContent;