import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { HeaderBlock } from './../../elements/SharedElements';

const Header = (props) => {
    
    const headerImg = useStaticQuery(graphql`
        query Background {
            allContentfulAsset(filter: {
                file: {
                    fileName: {
                        eq: "header-image.jpg"
                    }
                }
            }){
                edges {
                    node {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `)

    return  (
        <HeaderBlock style={{backgroundImage: `url(${headerImg.allContentfulAsset.edges[0].node.file.url})`}}>
            <div className="container">
                <h1>{ props.page }</h1>
            </div>
        </HeaderBlock>
    )
    
}

export default Header;