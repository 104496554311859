import styled from 'styled-components';

export const PageNotFound = styled.section`

    background: ${ props => props.theme.greyColor };

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5rem 0;

        .page-not-found-details {
            text-align: center;
            svg {
                font-size: 10rem;
                color: ${ props => props.theme.secondaryColor};
            }

            h2 {
                font-size: 2.2rem;
                margin: 2rem 0;
            }

            p {
                margin-bottom: 2rem;
            }
        }
    }

`