import React from "react"
import Layout from './../components/shared/Layout';
import PageNotFoundContent from './../components/404/PageNotFoundContent';

const PageNotFound = () => (
  <Layout title="Page Not Found">
      <PageNotFoundContent />
  </Layout>
)

export default PageNotFound;
